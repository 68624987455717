import React from "react";
import "./App.css";
import Bingo from "./components/Bingo/Bingo.component";
import Play from "./components/Play/Play.component";
import NotFound from "./components/NotFound/NotFound.component";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Link to="/">Home</Link>
          <Link to="/play">Play</Link>
          <div>
            <Switch>
              <Route exact path="/">
                <Bingo />
              </Route>
              <Route path="/play">
                <Play />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </header>
      </div>
    </Router>
  );
};

export default App;
