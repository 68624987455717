import React from "react";
import { IBingo } from "../../Shared/interfaces/bingo.interface";
import Card from "./Card.component";
import styled from "styled-components";
import download from "downloadjs";

const CardIdText = styled.span`
    color: white;
    font-size: 8px;
    text-align:left;
`;

const Button = styled.button`
    height: 2rem;
    border: none;
    margin: 1rem;
`;


function Cards(props: { bingo: IBingo }) {
    const exportBingo = function() {
        download(JSON.stringify(props.bingo), `${props.bingo.name}.json`, "text/javascript");
    }

    return (
    <div>
      <h1>{props.bingo.name}</h1>
      <Button onClick={exportBingo}>Export bingo</Button>
      <Button>Export PDF</Button>
      {props.bingo.cards.map(card => {
        return (
            <div key={card.id}>
        <CardIdText>{card.id}</CardIdText>
        <Card card={card}></Card>
        </div>);
      })}
    </div>
  );
}

export default Cards;
