import React, { Component } from "react";

function NotFound() {
  return (
    <div className="Contact">
      <h1>Not Found Page</h1>
    </div>
  );
}
export default NotFound;
