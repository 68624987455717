import React from 'react';
import {ICard} from '../../Shared/interfaces/card.interface';
import styled from 'styled-components';

const CardBox = styled.div`
    background-color: white;
    display: grid;
    grid-template-columns: repeat(9, 3rem);
`;

const CardSlot = styled.div`
    border: 1px solid;
    height: 3rem;
    color: black;
`;

function Card (props: {card: ICard}) {
    return (<CardBox id={props.card.id}>
        {props.card.slots.map(slot => <CardSlot key={slot.position.toString()} className="slot" >{slot.number}</CardSlot>)}
    </CardBox>);
}

export default Card;