import { IBingo } from "../../Shared/interfaces/bingo.interface";
import Shuffle from "./Shuffler";
import { ICard } from "../../Shared/interfaces/card.interface";
import { ISlot } from "../../Shared/interfaces/slot.interface";
import { MD5 } from "object-hash";

const generateCard = (name: String, numberOfElements: Number): ICard => {
  const numbers: Array<Number> = Array(89)
    .fill(1)
    .map((x, i) => i + 1);
  const positions = Array(27)
    .fill(1)
    .map((x, i) => i + 1);

  const positionsShuffled = Shuffle(positions);
  const numbersShuffled = Shuffle(numbers);

  const slots: Array<ISlot> = [];

  for (let i = 0; i < 27; i++) {
    //Removes first element
    const number =
      i < numberOfElements ? (numbersShuffled.shift() as number) : undefined;
    const slot: ISlot = { position: positionsShuffled[i], number };
    slots.push(slot);
  }

  // Sort by position
  slots.sort((a, b) => a.position - b.position);

  const card: ICard = { id: `${name} ${MD5(slots)}`, slots };

  return card;
};

const generateBingo = function (
  name: string,
  numberOfElements: number,
  numberOfCards: number
): IBingo {
  const cards: Array<ICard> = [];
  const checkCards: Array<String> = [];

  // TODO: Make sure there are no repeated cards
  while (cards.length < numberOfCards) {
    let card = generateCard(name, numberOfElements);
    let cardString = JSON.stringify(card);

    // Check card is unique
    if (!checkCards.includes(cardString)) {
      checkCards.push(cardString);
      cards.push(card);
    }
  }

  const bingo: IBingo = {
    name,
    cards,
  };

  return bingo;
};

export default generateBingo;
