import React, { useState } from "react";
import styled from "styled-components";

const Ball = styled.div`
  border-radius: 100%;
  background-color: red;
  height: 150px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Number = styled.div`
  background: black;
  color: white;
  padding: 0.5rem;
`;

function getRandomInt(max: number) {
  return Math.floor(Math.random() * Math.floor(max));
}

const setBallsToList = function(numberOfBalls: number): Array<number> {
  return Array(numberOfBalls)
    .fill(1)
    .map((x, i) => i + 1);
};

function Play() {
  const [number, setNumber] = useState<number>();
  //generates array with balls to play
  const [list, setList] = useState<Array<number>>(setBallsToList(89));

  // play a new ball without repeating
  const changeNumber = function() {
    const newList = list;
    const elementToRemove = getRandomInt(newList.length - 1);
    const newNumber = newList.splice(elementToRemove, 1).shift();

    setList(newList);
    setNumber(newNumber);
  };

  const replay = () => {
    setList(setBallsToList(89));
    setNumber(undefined);
  };

  if (!list.length) {
    return (
      <div>
        <p>Finished numbers!!!</p>
        <button onClick={replay}>Replay</button>
      </div>
    );
  }

  if (!number) {
    return (
      <div>
          <Ball className="ball">
        <Number className="number">{number}</Number>
      </Ball>
        <button onClick={changeNumber}>Start</button>
      </div>
    );
  }

  return (
    <div>
      <Ball className="ball">
        <Number className="number">{number}</Number>
      </Ball>
      <button onClick={changeNumber}>Next ball</button>
      <button onClick={replay}>Replay</button>
    </div>
  );
}

export default Play;
