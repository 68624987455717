import React, { useState, useCallback } from "react";
import generateBingo from "./BingoGenerator";
import useForm from "react-hook-form";
import Cards from "../Cards/Cards.component";
import { useDropzone } from "react-dropzone";
import { IBingo } from "../../Shared/interfaces/bingo.interface";
import styled from "styled-components";

const DropZone = styled.div`
  border-style: dashed;
  color: #fff;
  border-width: 0.3rem;
  width: 95vw;
  height: 15vh;
`;

function Bingo() {
  const [bingoGame, setBingo] = useState<IBingo>();
  const { register, handleSubmit, watch, errors } = useForm();

  const onDrop = useCallback(acceptedFiles => {
    const reader = new FileReader();

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      const binaryStr = reader.result;

      if (binaryStr) {
        setBingo(JSON.parse(binaryStr.toString()));
      } else {
      }
    };

    acceptedFiles.forEach((file: Blob) => reader.readAsText(file));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const onSubmit = (data: any) => {
    const bingoGame = generateBingo(data.name, 15, data.numberOfCards);
    setBingo(bingoGame);
  };
  if (bingoGame) {
    return <Cards bingo={bingoGame}></Cards>;
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* register your input into the hook by invoking the "register" function */}
        <input
          name="name"
          defaultValue="test"
          ref={register({ required: true })}
        />

        {/* include validation with required or other standard HTML validation rules */}
        <input
          name="numberOfCards"
          defaultValue="3"
          ref={register({ required: true })}
        />
        {/* errors will return when field validation fails  */}
        {errors.exampleRequired && <span>This field is required</span>}

        <input type="submit" />
      </form>
      <DropZone {...getRootProps()}>
        <input {...getInputProps()} accept=".json" />
        {isDragActive ? (
          <p>Or drop the bingo file here ...</p>
        ) : (
          <p>Drag 'n' drop bingo file here, or click to select files</p>
        )}
      </DropZone>
    </div>
  );
}

export default Bingo;
